<template>
  <main>
    <div class="contenu">
      <div style="position:relative;">
        <!-- mobile -->
          <div class="logo mobile">
            <router-link to="/"><img src="../assets/images/logo.png"></router-link>
          </div><img class="top-mobile mobile" src="../assets/images/top-vague-mobile.jpg">
          <!-- commun -->
            <div class="top-home"><img src="../assets/images/top-home.png"></div>
            <div class="top-moment"><img src="../assets/images/moments2.png"></div>
      </div><router-link to="/form"><img class="jouer-mobile" src="../assets/images/jouer-mobile.png"></router-link>
      <div class="home-mobile mobile"><img src="../assets/images/home-mobile-min.png"></div>
      <!-- desktop -->
        <div class="new-home">
          <div class="new-left"><img src="../assets/images/home-left3.png"></div>
          <div class="new-right"><img src="../assets/images/home-right2.png"></div>
        </div>
        <div class="mobile boutons">
        <router-link to="/howto"><img src="../assets/images/btn-comment.png"></router-link><br />
        <router-link to="/dotations"><img src="../assets/images/btn-dec.png"></router-link>
        </div>
    </div>
  </main>
</template>
<script>
export default {
  name: 'home' 
}
</script>